import { Box, Typography } from '@mui/material'
import Card from '~/src/components/mui-wrappers/Card'
import Title from '~/src/components/mui-wrappers/Title'
import GoBackButton from '~/src/components/navigation/GoBackButton'
import useGetPrivacyPolicyTemplate from '~/src/pages/legal/privacy-policy/styles/useGetPrivacyPolicyTemplate'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import RoutesEnum from '~/src/router/enums/routes.enum'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'

const PrivacyPolicy = () => {
  const pageName: keyof typeof RoutesEnum = 'PRIVACY_POLICY'
  const styles = useGetPrivacyPolicyTemplate()

  return (
    <SlideFromRight
      pathname={pageName}
      transition={{
        duration: .5
      }}
    >
      <DocumentTitleInjector pageName={pageName}/>

      <Box
        sx={styles?.getPageSx()}
      >
        <Card sx={styles?.getCardSx()}>
          <GoBackButton />
          <Title
            variant='h4'
            sx={{ my: 2 }}
          >Privacy Statement for Customer</Title>
          <Typography variant='body2'>The Privacy Statement for Customers are applicable to the relations between
            EASYORDER BVBA and the Customers.</Typography>

          <Title
            variant='h5'
            sx={{ my: 2 }}
          >Definitions</Title>
          <Typography variant='body2'>Customer: a natural person or legal entity placing an Order through the
            Platform</Typography>
          <Typography variant='body2'>Order: an order placed by the Customer with the Online Shop through the
            Platform.</Typography>
          <Typography variant='body2'>Agreement: an agreement between the Customer and the Online Shop regarding an Order
            and the delivery or collection of the Order.</Typography>
          <Typography variant='body2'>Platform: the apps and other management tools of EASYORDER BVBA and its affiliated
            companies and business partners on which the Online Shop is made available on.</Typography>
          <Typography variant='body2'>Platform Service: the commercial services and/or activities that are offered to the
            Customer by Platform, including showing the Offer, facilitation of the conclusion of Agreements and
            transmission of Orders to the Online shop.</Typography>
          <Typography variant='body2'>Online Shop: a company that offering products and/or services through the Platform
            with the purpose to sell these products/services to a customer.</Typography>

          <Title
            variant='h5'
            sx={{ my: 2 }}
          >Your privacy and EASYORDER BVBA data security</Title>
          <Typography variant='body2'>At EASYORDER BVBA, also refers as EASYORDER, and all our Shop Online members, we are
            concerned with your right to privacy and data security. Below are the guidelines we use for protecting the
            information you provide us when you visit our Platform.</Typography>

          <Title
            variant='h5'
            sx={{ my: 2 }}
          >Collection of Personal Information</Title>
          <Typography variant='body2'>EASYORDER BVBA processes personal data about you as part of your use of our Platform
            Services and/or because you provide this data to us yourself. An overview of the personal data we process is
            given below:</Typography>
          <ul>
            <Typography
              component='li'
              variant='body2'
            >E-mail address (Login)</Typography>
            <Typography
              component='li'
              variant='body2'
            >First and last name</Typography>
            <Typography
              component='li'
              variant='body2'
            >Telephone number</Typography>
            <Typography
              component='li'
              variant='body2'
            >Company name</Typography>
            <Typography
              component='li'
              variant='body2'
            >Address information: Street, number, City, Zip code, Country</Typography>
            <Typography
              component='li'
              variant='body2'
            >Device type</Typography>
            <Typography
              component='li'
              variant='body2'
            >Internet browser and device type</Typography>
          </ul>

          <Typography variant='body2'>We may also collect information relating to your use of our Platform through the use
            of various technologies. For example, when you visit our Platform, we may log certain information that your
            browser sends us, such as your IP address, browser type and language, access time, and referring Web site
            addresses. We may also collect information about the pages you view within our sites and other actions you
            take while visiting us. In addition, we may also use such technologies to determine whether you’ve opened an
            e-mail or clicked on a link contained in an e-mail. Collecting information in this manner allows us to collect
            statistics about our Platform usage and effectiveness and personalize your experience while you are on our
            Platform, as well as tailor our interactions with you.</Typography>

          <Title
            variant='h5'
            sx={{ my: 2 }}
          >Use of Personal Information</Title>
          <Typography
            variant='body2'
            sx={{ mb: 1 }}
          >The following paragraphs describe in more detail how EASYORDER BVBA and in expansion the Online shop, may use
            your personal information.</Typography>
          <Typography
            variant='body2'
            sx={{ mb: 1 }}
          >We only use your personal information for specific purposes. EASYORDER BVBA processes personal data from
            customers to provide delivery data for customers to the Online Shop. We also use the data you have given to
            create an account linked to Platform and made available to the Online shop. The information you provide us
            will be kept confidential and will solely be used to support your relationship with EASYORDER and Online
            Shops. It is our goal: to help you quickly find information on EASYORDER personalizing your experience on our
            Platform</Typography>
          <Typography
            variant='body2'
            sx={{ mb: 1 }}
          >You can easily opt-out of receiving further information from EASYORDER and/or Online Shop that you place an
            order with. If you have agreed to this upon placing your order, we also use your data to send you information
            about our various products and services, or about other products and services we feel may be of interest to
            you. Only EASYORDER and/or Online Shop will send you these direct mailings/messages. If you do not want to
            receive such mailings/messages simply tell us. In the case of newsletters or mailing lists, you will be able
            to 'unsubscribe' to these mailings at any time, otherwise you can easily opt-out of receiving further
            information from EASYORDER at any time by sending an e-mail to info@easyorderapp.com and/or by managing your
            personal profile on the platform.</Typography>
          <Typography
            variant='body2'
            sx={{ mb: 1 }}
          >We will not disclose your personal information to any outside organization for its use in without your consent.
            Information regarding you (such as name, address and phone number) will not be given or sold to any outside
            organization for its use in marketing or solicitation without your prior consent. Third party Internet sites
            (inclusive Online Shop website) and services accessible through this Platform have separate privacy and data
            collection practices, independent of us. EASYORDER has no responsibility or liability for these independent
            policies or actions.</Typography>
          <Typography
            variant='body2'
            sx={{ mb: 1 }}
          >We can transfer your personal data to third parties in connection with operating our business. Data may be
            transferred to or from our third parties, it may also be processed by these third parties and/or by our and
            their respective employees and service providers. We will take steps to ensure that these third parties will
            only process data in accordance with our instructions and take appropriate security measures to protect your
            data. This may include the transfer of data to other jurisdictions for processing at a destination outside the
            European Economic Area (EEA) which may not provide the same level of protection of personal data as within the
            EEA. By submitting your personal data or using our products and services, you agree to this transfer, storage
            and processing.</Typography>
          <Typography
            variant='body2'
            sx={{ mb: 1 }}
          >We will retain your personal data for as long as your account is active or as needed to provide you services.
            If you wish to request that we no longer use your Personal data information to provide you Platform services
            contact us by e-mail at info@easyorderapp.com. We will retain and use your Personal data information as
            necessary to comply with our legal obligations. In general, two years after last order the personal data will
            be removed from Platform, orders are kept one year before they are archived.</Typography>
          <Typography variant='body2'>We want to help you keep your personal information accurate. You can request the
            individual information that EASYORDER BVBA has collected about you via the Internet at
            info@easyorderapp.com.</Typography>

          <Title
            variant='h5'
            sx={{ my: 2 }}
          >Security and the protection of your Personal information</Title>
          <Typography variant='body2'>We store information you provide to us on secure servers and deploy appropriate
            technical and organizational security measures in the storage and disclosure of your personal data to try to
            prevent unauthorized access or loss. We take precautions that we deem to be reasonably necessary to help
            ensure Personal information is treated securely. When users need to enter financial information, such as a
            credit card number, that information is encrypted and is protected with an encryption protocol. Most modern
            web browsers should indicate when a user is on a secure page, for example some web browsers show a locked
            padlock icon at the bottom of the screen. While we take these steps to maintain the security of your
            information, you should be aware of the many information security risks that exist and take appropriate care
            to help safeguard your information. The nature of the internet is such that we cannot guarantee the security
            of the information you transmit to us electronically, and any transmission is at your own risk.</Typography>

          <Title
            variant='h5'
            sx={{ my: 2 }}
          >Cookies and clicking</Title>
          <Typography variant='body2'>Via our Platform (website/webshop) we can use cookies that your browser stores on
            your computer. A cookie is a small and simple file that is stored on your computer's hard disk. You can tune
            your browser to not receiving any cookies when visiting our Platform. However, if you do so this might limit
            your options to use all the services our website offers. Our Platform can register visitor data to compute the
            appeal of the various Platform sections. This information will be used only to upgrade our
            Platform.</Typography>
          <Typography variant='body2'>More information about cookies is detailed in the Cookie setting</Typography>

          <Title
            variant='h5'
            sx={{ my: 2 }}
          >Changes to this privacy statement</Title>
          <Typography variant='body2'>EASYORDER may at any time make changes to these Privacy Statement by posting revised
            Privacy Statement on the site. It is your responsibility to check periodically for any changes we make to the
            Privacy Statement. We therefore advise you to consult this privacy statement frequently to check any such
            change. Your continued use of the site after any changes to the Privacy statement means you accept the
            changes.</Typography>
        </Card>
      </Box>
    </SlideFromRight>
  )
}

export default PrivacyPolicy
