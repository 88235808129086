import StylesPrivacyPolicyServiceInterface
  from '~/src/pages/legal/privacy-policy/styles/StylesPrivacyPolicyServiceInterface'
import StylesPrivacyPolicyServiceStyleOne
  from '~/src/pages/legal/privacy-policy/styles/StylesPrivacyPolicyServiceStyleOne'
import StylesPrivacyPolicyServiceStyleThree
  from '~/src/pages/legal/privacy-policy/styles/StylesPrivacyPolicyServiceStyleThree'
import StylesPrivacyPolicyServiceStyleTwo
  from '~/src/pages/legal/privacy-policy/styles/StylesPrivacyPolicyServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getPrivacyPolicyTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesPrivacyPolicyServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesPrivacyPolicyServiceStyleTwo(muiTheme)
    case TemplateEnum.STYLE_THREE:
      return new StylesPrivacyPolicyServiceStyleThree(muiTheme)
    default:
      return new StylesPrivacyPolicyServiceStyleOne(muiTheme)
  }
}

export default getPrivacyPolicyTemplateService
