import getPrivacyPolicyTemplateService
  from '~/src/pages/legal/privacy-policy/styles/GetPrivacyPolicyTemplateService'
import StylesPrivacyPolicyServiceInterface
  from '~/src/pages/legal/privacy-policy/styles/StylesPrivacyPolicyServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useLayout } from '~/src/hooks/layout/useLayout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetPrivacyPolicyTemplate = (): StylesPrivacyPolicyServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('privacyPolicy')

  useLayout('privacyPolicy')

  return getPrivacyPolicyTemplateService(pageStyle, muiTheme)
}

export default useGetPrivacyPolicyTemplate
